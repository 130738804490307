import React from 'react';

export function MiddleEllipsis({
  text,
  classNames,
}: {
  text: string;
  classNames?: string;
}) {
  const middle = Math.round(text.length - 6);
  const firstPart = text.substring(0, middle);
  const lastPart = text.substring(middle, text.length);

  return (
    <div style={CONTAINER} className="text-base font-normal">
      <span className={`font-mono ${classNames}`} style={FIRST_PART}>
        {firstPart}
      </span>
      <span className={`font-mono ${classNames}`} style={SECOND_PART}>
        {lastPart}
      </span>
    </div>
  );
}

const CONTAINER: React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};
const PARTS: React.CSSProperties = {
  display: 'inline-block',
  verticalAlign: 'bottom',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textTransform: 'lowercase',
};
const FIRST_PART: React.CSSProperties = {
  ...PARTS,
  maxWidth: 'calc(100% - 4em)',
  minWidth: '1.75em',
  textOverflow: 'ellipsis',
};
const SECOND_PART: React.CSSProperties = {
  ...PARTS,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};
