import { useDarkSide } from '#lib/hooks/useDarkSide';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@nextui-org/react';
import React, { useState } from 'react';
import cn from 'classnames';

type VerifiedCollectionProps = {
  isVerified: boolean;
};

export const VerifiedCollectionPopoverSmall = ({
  isVerified,
}: VerifiedCollectionProps) => {
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState<boolean>(
    colorTheme === 'light' ? true : false
  );

  const renderCreatorView = () => {
    return <>{isVerified ? 'Verified by Dropspot.' : 'Not yet verified.'}</>;
  };

  return (
    <>
      <div className="flex flex-row items-center">
        <Tooltip
          content={renderCreatorView()}
          placement={'bottom'}
          color={darkSide == true ? 'invert' : 'default'}
        >
          <CheckBadgeIcon
            className={cn(
              'rounded-full flex items-center justify-center h-5 w-5',
              { 'text-blue-500 group:hover:text-blue-500': !!isVerified },
              { 'text-gray-600 opacity-0': !isVerified }
            )}
          />
        </Tooltip>
      </div>
    </>
  );
};
