import { useEffect, useState } from 'react';

export function useDarkSide() {
  const [theme, setTheme] = useState<string>(() => {
    if (typeof window === 'undefined') {
      return 'light';
    }
    return window.localStorage.getItem('theme') || 'light';
  });

  const colorTheme: string = theme === 'dark' ? 'light' : 'dark';

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);
    localStorage.setItem('theme', theme);
  }, [theme, colorTheme]);

  return { colorTheme, setTheme };
}

// import { useEffect, useState } from 'react';

// export function useDarkSide() {
//   const [theme, setTheme] = useState<string>('light');

//   useEffect(() => {
//     if (typeof window === 'undefined') {
//       return;
//     }
//     setTheme(window.localStorage.getItem('theme') || 'light');
//   }, []);

//   const colorTheme: string = theme === 'dark' ? 'light' : 'dark';

//   useEffect(() => {
//     const root = window.document.documentElement;
//     root.classList.remove(colorTheme);
//     root.classList.add(theme);
//     localStorage.setItem('theme', theme);
//   }, [theme, colorTheme]);

//   return { colorTheme, setTheme };
// }
