import { FC, memo, useEffect, useRef } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { connectStateResults } from 'react-instantsearch-dom';

import isEqual from 'react-fast-compare';
import {
  InstantSearch,
  Hits,
  Configure,
  Index,
  Highlight,
} from 'react-instantsearch-dom';
import type { Hit, StateResultsProvided } from 'react-instantsearch-core';
import algoliasearch from 'algoliasearch';

import type { Asset, Project } from '#types/index';
import { DSSearchBox } from 'components/Search/DSSearchBox';
import constants from '#lib/constants';
import { Divider } from '#components/Divider/Divider';
import { BrowserView, MobileView } from 'react-device-detect';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { NextImage } from '#components/Assets/NextImage';
import Link from 'next/link';
import { useRouter } from 'next/router';

const searchClient = algoliasearch(
  '91RRC8EAH8',
  'f1f7d0552a938afdece75849a9b79abc'
);

export const Search: FC = () => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const assetIndex = constants.ALGOLIA_ASSET_INDEX;
  const projectIndex = constants.ALGOLIA_PROJECT_INDEX;

  const router = useRouter();
  useEffect(() => {
    const aidValue = router.query.aid;

    if (aidValue) {
      setIsSearchActive(false);
    }
  }, [router.query.aid]);

  return (
    <InstantSearch searchClient={searchClient} indexName={assetIndex}>
      <Configure hitsPerPage={5} filters={`status:Minted`} />

      <span className="w-full xl:w-96 flex flex-col justify-start mr-6">
        <button
          onClick={() => setIsSearchActive(true)}
          className="border border-gray-300 dark:border-gray-600/80 shadow-sm text-lg px-5 pr-9 py-2 rounded-full bg-gray-50/40 dark:bg-gray-800/50 hover:bg-white/80 duration-200 transform  hover:-translate-y-0.5 hover:shadow-md transition-transform  font-medium"
        >
          <span className="w-full flex justify-start items-center gap-3">
            <MagnifyingGlassIcon className="w-5 h-5  dark:text-gray-400" />
            <p className="text-base dark:text-gray-400 font-light pt-0.5">
              Collections or NFTs
            </p>
          </span>
        </button>
      </span>

      <Transition
        show={isSearchActive}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setIsSearchActive(false)}
        >
          <div className="min-h-screen px-4 py-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-xl backdrop-brightness-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="h-full lg:inset-0 lg:m-auto w-12/12 sm:w-screen lg:w-[36rem] text-left align-top my-8 overflow-hidden transition-all transform bg-gray-100 dark:bg-gray-800 shadow-xl rounded-2xl">
                <div className="flex flex-col mb-6">
                  <DSSearchBox
                    placeholder="Collections or NFTs"
                    className="h-12 w-full px-4 rounded-t-2xl"
                    showResults={(show) => setIsSearchActive(show)}
                  />

                  <div
                    style={{ height: 'calc(100% - 5rem)' }}
                    className="ais-Hits bg-gray-100 dark:bg-gray-800 z-50 overflow-y-auto px-4 "
                  >
                    <Index indexName={projectIndex}>
                      <NoResultsHandler
                        notFound={
                          <>
                            <ol className="ais-Hits-list flex flex-col gap-2 mt-0">
                              <li className=" dark:bg-gray-800">
                                <h2 className="font-bold text-base text-gray-700 mt-1 dark:text-gray-300 dark:font-medium">
                                  Collections
                                </h2>

                                <Divider />
                              </li>
                              <p className="dark:text-gray-300  text-sm font-light py-2 ">
                                No matching collections found
                              </p>
                            </ol>
                          </>
                        }
                      >
                        <ol className="ais-Hits-list flex flex-col gap-2 mt-1 dark:bg-gray-800">
                          <li>
                            <h2 className="font-bold text-base text-gray-700 mt-0 dark:text-gray-300 dark:font-medium">
                              Collections
                            </h2>
                            <Divider />
                          </li>
                          <Hits<Hit<Project>>
                            hitComponent={(props) => (
                              <ProjectHit
                                {...props}
                                onLinkClick={() => setIsSearchActive(false)}
                              />
                            )}
                          />
                        </ol>
                      </NoResultsHandler>
                    </Index>
                    <NoResultsHandler
                      notFound={
                        <>
                          <ol className="ais-Hits-list flex flex-col gap-2 mt-2">
                            <li>
                              <h2 className="font-bold text-base text-gray-700  mt-2 dark:text-gray-300 dark:font-medium">
                                NFTs
                              </h2>

                              <Divider />
                            </li>
                            <p className="dark:text-gray-300 text-sm font-light py-2">
                              No matching NFTs found
                            </p>
                          </ol>
                        </>
                      }
                    >
                      <>
                        <ol className="ais-Hits-list flex flex-col gap-1">
                          <li>
                            <h2 className="font-bold text-base text-gray-700 mt-2 dark:text-gray-300 dark:font-medium">
                              NFTs
                            </h2>

                            <Divider />
                          </li>
                          <Hits<Hit<Asset>> hitComponent={AssetHit} />
                        </ol>
                      </>
                    </NoResultsHandler>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </InstantSearch>
  );
};

type NoResultsHandlerProps = StateResultsProvided & {
  children: React.ReactNode;
  notFound: React.ReactNode;
};

function NoResultsHandlerComponent({
  children,
  searchState,
  searchResults,
  notFound,
}: NoResultsHandlerProps) {
  if (searchState?.query && searchResults?.nbHits === 0) {
    return <>{notFound}</>;
  }

  return <>{children}</>;
}

const NoResultsHandler = connectStateResults(
  memo(NoResultsHandlerComponent, isEqual)
);

function AssetHit({ hit: asset }: { hit: Hit<Asset> }) {
  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <Link href={`/nft/${asset.id}`} key={asset.id}>
      <a>
        <div className="flex flex-1 my-2 gap-3 items-center bg-gray-50 dark:bg-gray-900  dark:text-white hover:bg-white hover:shadow-md hover:-translate-y-1 rounded-lg px-2 py-2 transform duration-150 transition">
          <div className="w-[3rem] h-[3rem] rounded-lg overflow-hidden bg-blue-200 relative border dark:border-gray-200">
            {(asset.assetUrl != 'holding' ||
              asset.thumbnailUrl != 'holding') && (
              <NextImage
                src={
                  asset.thumbnailUrl ||
                  asset.assetUrl ||
                  'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
                }
                width={75}
                height={75}
                quality={75}
                b64={asset.thumbnailB64}
                priority={true}
                className={'object-cover object-top shadow-2xl rounded-lg'}
                sizes={
                  '(max-width: 768px) 40vw, (max-width: 1200px) 10vw, 10vw'
                }
              />
            )}
          </div>
          <div className="flex-1  flex flex-col">
            <p className="text-base ml-2">
              <Highlight attribute="title" hit={asset} tagName="strong" />
            </p>
          </div>
          <div className="font-light text-sm text-gray-700 dark:text-gray-400 mr-2">
            {asset.algoliaProjectFacet &&
              JSON.parse(asset.algoliaProjectFacet).title}
          </div>
        </div>
      </a>
    </Link>
  );
}

interface ProjectHitProps {
  hit: Hit<Project>;
  onLinkClick: () => void;
}

const ProjectHit: React.FC<ProjectHitProps> = ({
  hit: project,
  onLinkClick,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <Link href={`/marketplace/${project.tokenPrefix}`} key={project.id}>
      <a onClick={() => onLinkClick()}>
        <div className="relative flex flex-row mb-2 gap-2 items-center bg-gray-50 dark:bg-gray-900 dark:text-white hover:bg-white hover:-translate-y-1 hover:shadow-md rounded-lg px-2 py-2 transform duration-150 transition">
          <div className="w-[3rem] h-[3rem] rounded-lg overflow-hidden bg-blue-200 relative ">
            {project.heroImage && project.heroImage != 'holding' && (
              <NextImage
                src={project.heroImage}
                width={60}
                height={60}
                quality={80}
                b64={project.heroImageB64}
                priority={true}
                className={'object-cover object-top shadow-2xl rounded-lg'}
                sizes={
                  '(max-width: 768px) 40vw, (max-width: 1200px) 10vw, 10vw'
                }
              />
            )}
          </div>
          <h1>
            <Highlight
              attribute="name"
              hit={project}
              className="text-base ml-2"
              tagName="strong"
            />
          </h1>
        </div>
      </a>
    </Link>
  );
};
