import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import Link from 'next/link';
import { useNotificaitons } from '#lib/hooks/useNotifications';
import { InlineLoading } from '#components/Loading/InlineLoading';
import { Tooltip } from '@nextui-org/react';
import cn from 'classnames';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { Divider } from '#components/Divider/Divider';
import { markNotificationsAsSeen } from '#lib/firestore';
import { BellIcon, CogIcon } from '@heroicons/react/24/solid';
import {
  useCreator,
  useCreatorNotificationPreferences,
} from '#lib/hooks/useCreator';
import { NextImage } from '#components/Assets/NextImage';
export interface NotificationsMenuProps {
  userId?: string;
}

export function NotificationsMenu({ userId }: NotificationsMenuProps) {
  dayjs.extend(relativeTime);
  const { loading, notifications } = useNotificaitons({ userId: userId });
  const { preferences, loading: prefsLoading } =
    useCreatorNotificationPreferences({ creatorId: userId || '' });
  const { creator } = useCreator({ creatorId: userId });
  const setAllAsSeen = () => {
    console.log('setting all as seen');

    if (!!notifications && notifications.length > 0 && !!userId) {
      markNotificationsAsSeen(userId, notifications);
    }
  };
  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <div>
            <Tooltip content={'Notifications'} placement="bottom">
              <Menu.Button
                className={cn(
                  'w-11 h-11 relative rounded-full   bg-gray-100/50 dark:bg-gray-900/50 dark:border-gray-700 text-gray-900 dark:text-white  tracking-wider flex justify-center items-center text-sm font-semibold border border-gray-300 hover:scale-105 hover:-translate-y-1 duration-200 transition shadow-sm ',
                  {
                    'ring-1 dark:ring-green-500 ring-green-500':
                      !!notifications && notifications?.length > 0,
                  }
                )}
              >
                <BellIcon className="w-6 h-6 text-gray-700 dark:text-white" />
                {!preferences?.notifyOnSale && !prefsLoading && (
                  <div className="w-3 h-3 rounded-full bg-blue-400 absolute top-0 right-0"></div>
                )}
                {notifications && notifications?.length > 0 && (
                  <div className="w-5 h-5 text-xs font-bold animate-none rounded-full dark:bg-green-500 bg-green-600 absolute -top-2 -right-1 flex items-center justify-center text-white">
                    {notifications?.length}
                  </div>
                )}
              </Menu.Button>
            </Tooltip>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="z-50 origin-top-right fixed md:absolute right-0 max-w-[30rem] w-full sm:w-max rounded-lg shadow-lg py-4 bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none mt-2 dark:border-gray-800 border"
            >
              <div className="mx-5 flex flex-col gap-3 z-20">
                <div className="flex justify-between items-center">
                  <h2 className="dark:text-gray-400 text-lg font-medium mt-1">
                    Notifications
                  </h2>
                  <div
                    className={cn(
                      'h-7 w-7 flex items-center justify-center text-white rounded-full ',
                      {
                        'bg-green-600':
                          !!notifications && notifications?.length > 0,
                      },
                      {
                        'dark:bg-gray-700 bg-gray-500':
                          !notifications || notifications.length < 1,
                      }
                    )}
                  >
                    {!!notifications && notifications.length}
                  </div>
                </div>
                <Divider />
                {!!loading && (
                  <div className="flex items-center justify-center h-6">
                    <InlineLoading ballSize={6} />
                  </div>
                )}
                {!loading && !!notifications && notifications?.length == 0 && (
                  <div className="text-gray-600 text-center">
                    You are up to date!{' '}
                  </div>
                )}
                <span className="mt-2 flex flex-col gap-4 mb-1 max-h-96 lg:max-h-[500px] overflow-scroll scrollbar-hide">
                  {!loading && !!notifications && notifications?.length > 0 && (
                    <>
                      {notifications?.map((notificaiton) => {
                        return (
                          <React.Fragment key={notificaiton.id}>
                            <Link href={`/profile?tab=notifications`}>
                              <a>
                                <div className="flex flex-row gap-3 justify-between items-center">
                                  <div className="relative w-12 h-12 flex-initial">
                                    {/* <img
                                      className="w-12 h-12 rounded-lg object-cover"
                                      src={notificaiton.thumbnail}
                                      alt=""
                                    /> */}
                                    <NextImage
                                      src={notificaiton.thumbnail}
                                      priority={false}
                                      width={100}
                                      height={100}
                                      layout="fill"
                                      className="object-cover w-full h-full rounded-lg"
                                    />
                                  </div>
                                  <div className="flex flex-col flex-1 justify-center">
                                    <div className="dark:text-white font-bold flex justify-between">
                                      <h3>{notificaiton.title}</h3>
                                    </div>
                                    <p className="font-light flex w-full text-sm text-gray-400 flex-nowrap overflow-clip overflow-ellipsis">
                                      {notificaiton.message}
                                    </p>
                                  </div>
                                  <p className="font-light text-gray-400 text-sm">
                                    {dayjs(notificaiton.creationDate).fromNow()}
                                  </p>
                                </div>
                              </a>
                            </Link>
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}
                </span>
                <Divider />
                <div className="flex flex-row justify-between items-center mt-1 gap-1 mb-2">
                  <a
                    className="dark:text-gray-400 text-gray-700 hover:text-black dark:hover:text-white text-sm mt-1 cursor-pointer"
                    onClick={() => {
                      setAllAsSeen();
                    }}
                  >
                    Mark all as seen
                  </a>
                  <Link href="/account?tab=notifications">
                    <a>
                      {!preferences?.notifyOnSale && !prefsLoading ? (
                        <>
                          <span className="bg-blue-400 rounded-full pt-1 pb-1.5 px-3 text-sm text-white font-normal ">
                            setup
                          </span>
                        </>
                      ) : (
                        <CogIcon className="w-5 h-5 dark:text-gray-400 text-gray-700 hover:text-black dark:hover:text-white hover:scale-102 duration-200" />
                      )}
                    </a>
                  </Link>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
