import { useFirebaseCurrentUser } from '#lib/hooks/useFirebaseCurrentUser';
import { useWalletStore, WalletContextProps } from '#lib/wallet/WalletContext';
import { useCallback, useEffect, useState } from 'react';
import {
  TrackedTransactionsState,
  useTrackedTransactionsStore,
} from '../Wallet/TransactionActivity';

type WalletMenuProps = {
  uid: string;
  mode?: 'mobile' | 'desktop';
  toggleWalletSlideOut: () => void;
};

function useTrackedTransactionsStoreSelector(state: TrackedTransactionsState) {
  return {
    registerListener: state.addListenerIfNotAlreadyAdded,
    unsubscribe: state.unsubscribe,
  };
}

function walletStoreSelector(state: WalletContextProps) {
  return {
    wallet: state.wallet,
    adaBalance: state.adaBalance,
    registerBalanceListener: state.registerBalanceListener,
  };
}

export function WalletMenu({
  mode = 'desktop',
  uid,
  toggleWalletSlideOut,
}: WalletMenuProps) {
  // const { wallet, adaBalance, registerBalanceListener } = useCardanoWallet();

  const { wallet, adaBalance, registerBalanceListener } =
    useWalletStore(walletStoreSelector);

  const [network, setNetwork] = useState<number>();

  useEffect(() => {
    if (wallet) {
      wallet.getNetworkId().then((id) => setNetwork(id));
    }
  }, [wallet]);

  const { currentUser } = useFirebaseCurrentUser();

  const { inProgressTransactions } = useTrackedTransactionsStore(
    useCallback(
      (state: TrackedTransactionsState) => {
        return {
          inProgressTransactions: currentUser?.uid
            ? state.trackedTransactions
                .get(currentUser.uid)
                ?.filter((t) => t.status !== 'COMPLETED')
            : [],
        };
      },
      [currentUser?.uid]
    )
  );

  const { registerListener, unsubscribe } = useTrackedTransactionsStore(
    useTrackedTransactionsStoreSelector
  );

  useEffect(() => {
    if (!currentUser) return;
    registerListener(currentUser.uid);
    return () => {
      unsubscribe(currentUser.uid);
    };
  }, [currentUser, registerListener, unsubscribe]);

  useEffect(() => registerBalanceListener(), [registerBalanceListener]);
  return (
    <>
      <div
        onClick={() => toggleWalletSlideOut()}
        className="relative cursor-pointer sm:min-w-[8rem] flex flex-row gap-2 justify-between items-center dark:hover:bg-gray-900 hover:-translate-y-1 duration-200 transition border border-gray-300 dark:border-gray-600 py-2 px-2 sm:pl-3 sm:pr-4 rounded-full"
      >
        <div className="mx-1 my-1">
          {!!wallet?.icon && (
            <img
              src={wallet?.icon || '/img/cardano-logo-white.webp'}
              className="w-5 h-5 object-contain"
              alt={`${wallet?.name}-icon`}
            />
          )}
        </div>
        {mode != 'mobile'}

        {!adaBalance && (
          <span className="loading loading-spinner loading-xs " />
        )}

        {!!adaBalance && (
          <>
            <span className="dark:text-white font-medium text-base xl:block hidden">
              {network === 0 && 't'}₳ {adaBalance ?? 0.0}
            </span>

            <span className="dark:text-white font-medium text-base xl:hidden hidden sm:block">
              {network === 0 && 't'}₳{' '}
              {adaBalance
                ? parseFloat(adaBalance.replace(/,/g, '')) >= 1000
                  ? adaBalance.split('.')[0]
                  : adaBalance
                : '0.0'}
            </span>
          </>
        )}

        {!!inProgressTransactions && inProgressTransactions.length > 0 && (
          <span className="flex h-6 w-6 m-1 absolute -top-3 -right-3">
            <span className="animate-ping inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="text-sm text-white font-bold absolute inline-flex rounded-full m-0.5 h-5 w-5 bg-sky-500 items-center justify-center p-1">
              {inProgressTransactions.length}
            </span>
          </span>
        )}
      </div>
    </>
  );
}
