import { Project } from '#types/index';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export interface DegenMode {
  degenMode: boolean;
  selectedCollection?: Project;
  base64?: string;
  toggle: () => void;
  setSelectedCollection: (collection?: Project) => void;
  setBase64: (base64?: string) => void;
}

export const useDegenStore = create<DegenMode>()(
  // devtools(
  persist(
    (set) => ({
      degenMode: false,
      selectedCollection: undefined,
      setSelectedCollection: (collection) => {
        set(() => ({ selectedCollection: collection }));
      },
      setBase64: (base64) => {
        set(() => ({ base64: base64 }));
      },
      toggle: () =>
        set((state) => ({
          degenMode: !state.degenMode,
        })),
    }),
    {
      name: 'dg-mode',
      // getStorage: () => ({
      //   getItem: async (name) => {
      //     console.log('get item', name);
      //     return name;
      //   },
      //   setItem: async (name, value) => {
      //     console.log('set item', name, value);
      //   },
      //   removeItem: async (name) => {
      //     console.log('remove item', name);
      //   },
      // }),
    }
  )
);
