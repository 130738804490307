import { useState, useEffect } from 'react';
import { Notification } from '#types/index';
import { getNotificationsListener } from '#lib/firestore';

type useNotificationProps = {
  userId?: string;
  initialNotifications?: Notification[];
  limit?: number;
};

export const useNotificaitons = ({
  userId,
  initialNotifications,
  limit = 12,
}: useNotificationProps) => {
  const [notifications, setNotifications] = useState<
    Notification[] | undefined
  >(initialNotifications);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setNotifications(undefined);
      return;
    }

    setLoading(true);

    return getNotificationsListener(userId, limit, (data) => {
      setNotifications(data);
      setLoading(false);
    }); // Return the result of this so that we can clean up if / when we need to.
  }, [limit, userId]);

  return { notifications, loading };
};
