import { connectSearchBox } from 'react-instantsearch-dom';
import type { SearchBoxProvided } from 'react-instantsearch-core';
import cn from 'classnames';
import { useRef } from 'react';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';

interface DSSearchBoxProps extends SearchBoxProvided {
  showResults: (show: boolean) => void;
  className?: string;
  placeholder?: string;
}

function SearchBox({
  currentRefinement,
  refine,
  showResults,
  className,
  placeholder = 'Search collections',
}: DSSearchBoxProps) {
  const searchBoxRef = useRef<HTMLInputElement>(null);
  return (
    <div className="ais-SearchBox flex-1 w-full">
      <form
        action=""
        className="ais-SearchBox-form"
        noValidate
        role="search"
        onSubmit={(e) => {
          e.preventDefault();
          searchBoxRef.current?.blur();
        }}
      >
        <MagnifyingGlassCircleIcon className="w-6 h-6 absolute left-3 top-5 dark:text-white" />
        <input
          ref={searchBoxRef}
          type="search"
          className={cn(
            'focus:outline-none pl-12 py-8 dark:text-white dark:bg-gray-800',
            className
          )}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={placeholder}
          spellCheck={false}
          maxLength={512}
          value={currentRefinement}
          onChange={(event) => {
            refine(event.currentTarget.value);
          }}
        />
      </form>
    </div>
  );
}

export const DSSearchBox = connectSearchBox<DSSearchBoxProps>(SearchBox);
