import { MiddleEllipsis } from '#components/MiddleEllipsis/MiddleEllipsis';
import { copyTextToClipboard } from '#components/ShareButton/ShareButton';
import { cardanoExplorerURL } from '#lib/plutus/utils';
import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useState } from 'react';

export type TxDisplayProps = {
  txHash: string;
  widthClass?: string;
};

export const TxDisplay = ({ txHash, widthClass = 'w-48' }: TxDisplayProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(txHash || `No ${txHash}`)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className=" flex items-center gap-2">
        <div
          className={`${widthClass} cursor-pointer`}
          onClick={() => handleCopyClick()}
        >
          <MiddleEllipsis text={txHash} classNames="text-sm" />
        </div>
        {isCopied ? (
          <CheckIcon
            className="w-4 cursor-pointer text-green-500"
            onClick={() => handleCopyClick()}
          />
        ) : (
          <DocumentDuplicateIcon
            className="w-4 cursor-pointer"
            onClick={() => handleCopyClick()}
          />
        )}

        <Link
          href={`${cardanoExplorerURL()}/transaction/${txHash}`}
          target={'_blank'}
        >
          <a target={'_blank'}>
            <ArrowTopRightOnSquareIcon className="w-4" />
          </a>
        </Link>
      </div>
    </>
  );
};
