import React, { FC } from 'react';

type IframeCompProps = {
  src: string;
  classNames?: string;
};
export const IframeComponent: FC<IframeCompProps> = ({ src, classNames }) => {
  // only renders if url have changed!
  return <iframe src={src} className={classNames}></iframe>;
};
