import { Asset } from '#types/index';
import { assets } from '@blockfrost/blockfrost-js/lib/endpoints/api/assets';
import { NextImage } from '../NextImage';
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import React from 'react';
import { IframeComponent } from '#components/iFrame';
import cn from 'classnames';
const ReactPlayerWrapper = dynamic(() => import('../DSVideoPlayer'), {
  ssr: false,
});

export type AssetImageProps = {
  asset: Asset;
  mini?: boolean;
  thumb?: boolean;
  assetUrl?: string;
};

export const AssetImage = ({
  asset,
  mini,
  thumb,
  assetUrl,
}: AssetImageProps) => {
  //figrue out the source
  const [mediaType, setMediaType] = useState(asset.mediaType || 'image/jpeg');


  function extractPtValue(jsonString:string) {
    try {
      const jsonObject = JSON.parse(jsonString);
      return jsonObject.pt;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null; // Return null or handle the error as needed
    }
  }
  const [ptValue, setPtValue] = useState<string | undefined>(extractPtValue(asset.algoliaProjectFacet || ''));
  // Example usage:
  useEffect(() => {
    setPtValue(extractPtValue(asset.algoliaProjectFacet || ''));
  }, [asset]);

  const [assetSrc, setAssetSrc] = useState<string>(
    asset.thumbnailUrl || asset.assetUrl || ''
  );

  const [videoURL, setVideoURL] = useState<string | undefined>();

  useEffect(() => {
    if (
      asset.mediaType == 'image/gif' ||
      asset.mediaType?.startsWith('image')
    ) {
      //look for other assets in files:

      if (!!asset.filesImported) {
        asset.filesImported.forEach((file) => {
          if (file.mediaType == 'video/mp4') {
            setVideoURL(file.dsFile);
            console.log('setting video url>>>>>>> ', file.dsFile);
          }
        });
      }
    }
    setAssetSrc(asset.thumbnailUrl || asset.assetUrl || '');
  }, [asset]);

  return (
    <div className="flex flex-col h-full w-full ">
      <div className="w-full h-full relative overflow-hidden group sm:mx-0 object-contain rounded-xl shadow-2xl">
        {asset.assetType == 'ONCHAIN-HTML' ? (
          <div className=" h-full ">
            <IframeComponent
              classNames="w-full h-full"
              src={
                (asset.files &&
                  asset.files
                    ?.map((f) => {
                      return (f.src as string[]).reduce(
                        (str, conc) => `${str}${conc}`,
                        ''
                      );
                    })
                    .toString()) ||
                ''
              }
            />
          </div>
        ) : (
          <>
            <div
              className={cn(
                'w-full flex  h-full ',
                {
                  'bg-base-200':
                    asset.imageDisplayPreference == 'object-contain',
                },
                { 'min-h-[30rem] max-h-[36rem]': mini },
                { 'min-h-[4rem] max-h-[4rem] w-16 h-16': thumb },
                { 'min-h-[90rem] max-h-[120rem]': !mini && !thumb }
              )}
            >
              {!!videoURL && (
                <div className={cn('flex-1 w-full h-full ')}>
                  <ReactPlayerWrapper
                    src={videoURL}
                    shouldPlay={true}
                    showControls={true}
                    shouldContain={true}
                    muted={true}
                  />
                </div>
              )}

              {!videoURL && (
                <>
                  <NextImage
                    src={assetSrc === 'holding' ? assetUrl || ptValue : assetSrc }
                    priority={true}
                    b64={asset.thumbnailB64}
                    quality={100}
                    width={thumb == true ? 100 : 1200}
                    avoidCloudflare={true}
                    layout="fill"
                    className={`${
                      asset.imageDisplayPreference == 'object-contain'
                        ? 'object-contain'
                        : 'object-contain rounded-xl'
                    } `}
                  />
                </>
              )}
            </div>

            {/* {!!videoURL && (
              <>
                <div className="w-96 h-96 bg-orange-400">HI</div>
                <ReactPlayerWrapper
                  src={videoURL}
                  shouldPlay={true}
                  showControls={true}
                  muted={true}
                />
              </>
            )} */}
          </>
        )}
      </div>
      {/* <div className="w-full  py-4 bg-gray-700/10 shadow-inner flex flex-row gap-4 border border-gray-700 items-center px-4 mt-8 rounded-xl">
        <div className="w-24 h-24 bg-gray-800 rounded-lg relative overflow-hidden">
          <NextImage
            src={assetSrc}
            priority={false}
            b64={asset.thumbnailB64}
            layout="fill"
            quality={100}
            avoidCloudflare={true}
          />
        </div>
        {asset.filesImported &&
          asset.filesImported.map((file) => {
            return (
              <React.Fragment key={file.dsFilePath}>
                <div className="w-24 h-24 bg-gray-800 rounded-lg relative overflow-hidden">
                  {file.dsFile && file.mediaType.startsWith('image') && (
                    <NextImage
                      src={file.dsFile}
                      priority={false}
                      quality={100}
                      height={150}
                      width={150}
                      avoidCloudflare={false}
                    />
                  )}
                  {file.dsFile && file.mediaType.startsWith('video') && (
                    <ReactPlayerWrapper
                      src={videoURL}
                      shouldPlay={true}
                      showControls={true}
                      muted={true}
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div> */}
    </div>
  );
};
