import { VFC } from 'react';

type InlineLoadingProps = {
  ballSize?: number;
};

export const InlineLoading: VFC<InlineLoadingProps> = ({ ballSize = 14 }) => (
  <span className="flex gap-2 mt-2">
    <div
      style={{ width: ballSize, height: ballSize }}
      className="rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-gray-300 animate-bounce animation-delay-100"
    />
    <div
      style={{ width: ballSize, height: ballSize }}
      className="rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-gray-300 animate-bounce animation-delay-300"
    />
    <div
      style={{ width: ballSize, height: ballSize }}
      className="rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-gray-300 animate-bounce animation-delay-200"
    />
  </span>
);
