import Image from 'next/image';
import React, { useMemo } from 'react';
import { useState } from 'react';

type CloudflareLoaderProps = {
  src?: string;
  width?: number;
  height?: number;
  quality?: number;
  b64?: string;
  priority: boolean;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
  className?: string;
  avoidCloudflare?: boolean;
  sizes?: string;
  underloading?: boolean;
  avoidB64?: boolean;
};

export const NextImage = ({
  src,
  width,
  height,
  quality = 100,
  b64,
  priority,
  layout,
  className = 'object-cover',
  sizes,
  underloading,
  avoidB64 = false,
  avoidCloudflare = false,
}: CloudflareLoaderProps) => {
  const w = width;
  const params = [`width=${w}`];
  const paramsString = params.join(',');

  let filteredSrc = src;

  if (
    !filteredSrc ||
    filteredSrc === 'undefined' ||
    filteredSrc === 'null' ||
    filteredSrc === 'holding'
  ) {
    filteredSrc =
      'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png';
  }

  if (!filteredSrc.startsWith('data') && !avoidCloudflare) {
    filteredSrc = `https://dropspot.io/cdn-cgi/image/${paramsString}/${normalizeSrc(
      src ||
        'https://media.dropspot.io/placeholders%2FNo-Image-Placeholder.svg.png'
    )}`;
  }

  return (
    <Image
      blurDataURL={b64}
      src={filteredSrc}
      unoptimized={true}
      alt=""
      // width={width}
      // height={height}
      placeholder={!!b64 && !avoidB64 ? 'blur' : 'empty'}
      quality={quality}
      layout={'fill'}
      priority={priority}
      className={`${className} filter duration-200 `}
      sizes={sizes}
    />
  );
};

export const MemoNextImage = React.memo(NextImage);

export const MemoImage = React.memo(NextImage, (prevProps, nextProps) => {
  return prevProps.src === nextProps.src;
});

type loaderProps = {
  src: string;
  width: number;
  quality?: number;
};

const cloudflareLoader = ({ src }: loaderProps) => {
  const width = 200;
  const quality = 0.2;
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(',');
  console.log(
    'paramsString: ',
    `https://dropspot.io/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
  );
  return `https://dropspot.io/cdn-cgi/image/${paramsString}/${normalizeSrc(
    src
  )}`;
};

const normalizeSrc = (src: string) => {
  return src && src.startsWith('/') ? src.slice(1) : src;
};
